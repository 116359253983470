<template>
  <Card title="Creative Rotation" opened collapse withDivider>
    <FormControl :id="getId('creatives.rotationType')" v-slot="{ id }">
      <Box flex spaceX="6" spaceY="4" wrap="wrap">
        <RadioButton
          v-for="type in rotationTypeOptions"
          :id="id"
          :key="type.id"
          v-model="rotationType"
          :value="type.id"
          :title="type.title"
          :disabled="advancedOptimization"
        />
      </Box>
    </FormControl>
  </Card>
</template>

<script setup lang="ts">
import { Box, Card, FormControl, RadioButton } from '@lasso/luikit'
import { CreativeRotationType } from '@lasso/api-activation/activation'
import { objValues } from '@lasso/shared/utils'
import { computed } from 'vue'

import { useCardCreatives } from './useCardCreatives'
import { rotationTypeWhenLeversEnabled } from './consts'

const { rotationType, advancedOptimization, getId } = useCardCreatives()!

const rotationTypeMap: { [K in CreativeRotationType]: { id: K; title: string } } = {
  [CreativeRotationType.AUTO]: {
    id: CreativeRotationType.AUTO,
    title: 'Auto optimize',
  },
  [CreativeRotationType.EVEN]: {
    id: CreativeRotationType.EVEN,
    title: 'Evenly weight',
  },
  [CreativeRotationType.WEIGHTED]: {
    id: CreativeRotationType.WEIGHTED,
    title: 'Manually weight',
  },
  [CreativeRotationType.INHERITED]: {
    id: CreativeRotationType.INHERITED,
    title: 'Inherit weight from levers',
  },
}

const rotationTypeOptions = computed(() => {
  return objValues(rotationTypeMap).filter(({ id }) => advancedOptimization.value || id !== rotationTypeWhenLeversEnabled)
})
</script>
