import { arrayify, createInjectionState } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, nextTick, toValue, watch } from 'vue'
import { UseFormAddonsContext, useApi } from '@lasso/shared/hooks'
import { ChannelType, EndemicFormatType, useActivationApi } from '@lasso/api-activation/activation'

import { AdGroupCreative } from '../../../shared/creatives'

import { CardCreativesFormInput, CreativesFormInput } from './types'
import { defaultRotationType, rotationTypeWhenLeversEnabled } from './consts'
import { buildCreative } from './utils'
import { useProvideCreativesSearch } from './CreativesSelect'

export const [useProvideCardCreatives, useCardCreatives] = createInjectionState(({
  isCardEnabled,
  isInitiallyDraft,
  channelId,
  endemicFormatId,
  adGroupId,
  marketerId,
  accountId,
  advancedOptimization,
  creativePreviewBaseUrl,
  isDirectSold,
  shouldFetchByAccountId = true,
  ...methods
}: {
  isCardEnabled: RefOrGetter<boolean>
  isInitiallyDraft: boolean
  channelId: Readonly<Ref<ChannelType>>
  endemicFormatId: Readonly<Ref<EndemicFormatType | null>>
  adGroupId: Readonly<Ref<number>>
  marketerId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>
  advancedOptimization: Readonly<Ref<boolean>>
  creativePreviewBaseUrl: string
  isDirectSold: boolean
  shouldFetchByAccountId?: boolean
} & UseFormAddonsContext<CardCreativesFormInput>) => {
  const {
    useFieldModelsStartingWith,
    resetField,
    getId,
  } = methods

  const {
    rotationType,
    creatives,
  } = useFieldModelsStartingWith('creatives', [
    'rotationType',
    'creatives',
  ])

  const setInitialCreative = (creatives: CreativesFormInput['creatives']) => {
    resetField('creatives.creatives', { value: creatives })
  }

  useProvideCreativesSearch({
    isCardEnabled,
    shouldFetchByAccountId,
    channelId,
    marketerId,
    accountId,
    endemicFormatId,
    ...methods,
  })

  const adjustRotationType = () => {
    if (advancedOptimization.value) {
      if (rotationType.value !== rotationTypeWhenLeversEnabled) {
        rotationType.value = rotationTypeWhenLeversEnabled
      }
    }
    else {
      if (rotationType.value === rotationTypeWhenLeversEnabled) {
        rotationType.value = defaultRotationType
      }
    }
  }

  watch(advancedOptimization, () => {
    adjustRotationType()
  }, { immediate: true })

  watch(rotationType, async () => {
    await nextTick()
    adjustRotationType()
  })

  const { loading, onData, error, request } = useApi(
    useActivationApi().getAdGroupCreatives,
    () => {
      return (toValue(isCardEnabled) && !isInitiallyDraft)
        ? [{ adGroupId: adGroupId.value, paging: { skip: 0, pageSize: 9999999 } }]
        : null
    },
    { refetch: true },
  )

  onData(({ data }) => {
    setInitialCreative((data ?? []).map(buildCreative))
  })

  const deselectCreative = (creative: AdGroupCreative) => {
    creatives.value = creatives.value.filter(selectedCreative => selectedCreative.id !== creative.id)
  }

  const isCreativeSelected = (creative: AdGroupCreative) => {
    return creatives.value.some(selectedCreative => selectedCreative.id === creative.id)
  }

  const setCreativesSelected = (creativeToUpdate: AdGroupCreative | AdGroupCreative[], value: boolean) => {
    const creativesToUpdate = arrayify(creativeToUpdate)

    if (value) {
      const unselectedCreatives = creativesToUpdate.filter(creative => !isCreativeSelected(creative))

      if (unselectedCreatives.length > 0) {
        creatives.value = [...creatives.value, ...unselectedCreatives]
      }
    }
    else {
      creatives.value = creatives.value.filter((selectedCreative) => {
        return creativesToUpdate.every(creative => selectedCreative.id !== creative.id)
      })
    }
  }

  const clearCreatives = () => {
    creatives.value = []
  }

  const toggleCreativeSelected = (creative: AdGroupCreative) => {
    setCreativesSelected(creative, !isCreativeSelected(creative))
  }

  const getCreativePreviewUrl = (creative: AdGroupCreative) => {
    return `${creativePreviewBaseUrl}/api/creative/preview/${creative.id}`
  }

  return {
    isDirectSold,
    rotationType,
    loading,
    error,
    request,
    creatives,
    advancedOptimization,
    endemicFormatId,
    deselectCreative,
    isCreativeSelected,
    setCreativesSelected,
    toggleCreativeSelected,
    getCreativePreviewUrl,
    getId,
    clearCreatives,
  }
})
