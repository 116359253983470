<template>
  <FormControl v-slot="{ error: hasError }" :errorText="creativesError" hideLabel>
    <DropdownList
      ref="dropdownRef"
      trigger="manual"
      :hideOnClick="false"
      :width="`${dropdownWidth}px`"
      @clickOutside="hideDropdown"
    >
      <template #default="{ show }">
        <InputText
          :id="getId('creatives.creatives')"
          v-model="searchQueryDebounced"
          :error="hasError"
          :disabled="isLoadingAll"
          placeholder="Search for a creative"
          icon="search"
          disableValidation
          inputmode="search"
          autocomplete="off"
          @focus="show"
          @click="show"
        />
      </template>

      <template #dropdown>
        <Paper disablePadding>
          <CreativesSearchItemBase v-if="fetching || isLoadingAll" noHover>
            <Typography variant="overline1" color="textSecondary" uppercase>
              {{ isLoadingAll ? 'Selecting all creatives...' : 'Searching...' }}
            </Typography>
          </CreativesSearchItemBase>

          <ErrorState v-else-if="error" :error="error" @retry="request()" />

          <template v-else-if="searchedCreatives.length > 0">
            <Paper class="sticky top-0 z-20">
              <CreativesSearchItemBase noHover>
                <Checkbox
                  id="selectAllRecent"
                  :key="String(isAllSelected)"
                  :modelValue="isAllSelected"
                  :indeterminate="isAnySelected && !isAllSelected"
                  @update:modelValue="onSelectAll()"
                />
                <Typography variant="overline1" color="textSecondary" uppercase>
                  {{ isShowingRecentCreatives ? 'Recent' : 'Results' }} ({{ total }})
                </Typography>
              </CreativesSearchItemBase>
            </Paper>
            <Box flex col>
              <CreativesSearchListItem
                v-for="creative of searchedCreatives"
                :key="creative.id"
                :creative="creative"
                :selected="isCreativeSelected(creative)"
                :static="shouldHaveStaticIndication(creative, endemicFormatId)"
                @update:selected="handleSelect(creative)"
              />
              <InfiniteScroll
                v-if="!fetching"
                :disabled="!hasNextPage"
                :fetchNextPage="requestNextPage"
              />
            </Box>
          </template>
          <template v-else>
            <CreativesSearchItemBase noHover>
              <Typography variant="overline1" color="textSecondary" uppercase>
                No results found
              </Typography>
            </CreativesSearchItemBase>
          </template>
        </Paper>
      </template>
    </DropdownList>
  </FormControl>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, ref } from 'vue'
import { useElementSize } from '@vueuse/core'
import { Box, Checkbox, DropdownList, ErrorState, FormControl, InfiniteScroll, InputText, Paper, Typography } from '@lasso/luikit'
import { ComponentExposed } from '@lasso/shared/types'

import { AdGroupCreative } from '../../../../shared/creatives'

import { useCardCreatives } from '../useCardCreatives'
import { shouldHaveStaticIndication } from '../utils'

import CreativesSearchItemBase from './CreativesSearchItemBase.vue'
import CreativesSearchListItem from './CreativesSearchListItem.vue'
import { useCreativesSearch } from './useCreativesSearch'

const {
  isCreativeSelected,
  setCreativesSelected,
  toggleCreativeSelected,
  endemicFormatId,
  clearCreatives,
} = useCardCreatives()!

const {
  fetching,
  error,
  request,
  searchedCreatives,
  searchQueryDebounced,
  total,
  isShowingRecentCreatives,
  requestNextPage,
  hasNextPage,
  getId,
  creativesError,
  loadAllResults,
} = useCreativesSearch()!

const dropdownRef = ref<ComponentPublicInstance & ComponentExposed<typeof DropdownList> | null>(null)
const isLoadingAll = ref(false)

const { width: dropdownWidth } = useElementSize(dropdownRef)

const hideDropdown = () => {
  dropdownRef.value?.hide()
}

const handleSelect = (creative: AdGroupCreative) => {
  toggleCreativeSelected(creative)
  hideDropdown()
}

const isAllSelected = computed(() => searchedCreatives.value.every(isCreativeSelected))
const isAnySelected = computed(() => searchedCreatives.value.some(isCreativeSelected))

const onSelectAll = async () => {
  if (isAllSelected.value) {
    clearCreatives()
    hideDropdown()
  }
  else {
    isLoadingAll.value = true
    await loadAllResults()

    setCreativesSelected(searchedCreatives.value, !isAllSelected.value)
    hideDropdown()

    // wait until dropdown's closing animation is finished
    setTimeout(() => {
      isLoadingAll.value = false
    }, 100)
  }
}
</script>
